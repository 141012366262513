import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from '@/plugins/vuetify' // path to vuetify export
import { TiptapVuetifyPlugin } from 'tiptap-vuetify'
import AudioVisual from 'vue-audio-visual'
import $ from 'jquery'
import VueMask from 'v-mask'
import VuePictureSwipe from 'vue-picture-swipe'
// don't forget to import CSS styles
import 'tiptap-vuetify/dist/main.css'
import moment from 'moment'


Vue.config.productionTip = false
Vue.use(AudioVisual)
Vue.prototype.$ = $
Vue.use(VueMask)
Vue.component('vue-picture-swipe', VuePictureSwipe)
Vue.use(TiptapVuetifyPlugin, {
  vuetify,
  iconsGroup: 'mdi'
})

Vue.filter('formatDate', function(value) {
  if (value) {
    return moment(String(value)).format('MM/DD/YYYY hh:mm')
  }
})

new Vue({
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app')
