import Vue from 'vue'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.css'
import 'vuetify/dist/vuetify.js'
import '@mdi/font/css/materialdesignicons.css'

Vue.use(Vuetify, {
  iconfont: "md"
})

const opts = {}

export default new Vuetify(opts)