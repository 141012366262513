<template>
  <v-app>
    <router-view class="my-0 py-0" />
  </v-app>
</template>

<script>
export default {
  name: 'App',
  data () {
    return {
    }
  },
}
</script>

<style scoped>
  @font-face {
      font-family: Laube;
      src: url('./assets/fonts/Blue Vinyl Fonts - BlueVinyl-Bold.otf');
  }
</style>
