<template>
  <v-content>
    <div class="page">
      <div class="imgweb d-none d-sm-none d-md-block mt-0 pt-0">
        <AppBar :drawer="false"/>
        <!-- <div class="text-right">
          <span class="caption mx-3" style="color: #70445E; text-decoration: underline;">Voltar</span>
        </div> -->
        <div class="container fill-height">
          <v-row justify="start" class="mt-5">
          </v-row>
        </div>
      </div>
      <div class="imgmobi d-md-none d-lg-none d-xl-none pt-0 mt-0" style="overflow-y: auto !important;" v-scroll.self="onScroll">
        <AppBar :drawer="false"/>
        <div class="container">
        </div>
      </div>
    </div>
  </v-content>
</template>

<script>
import AppBar from '../components/AppBar.vue'
export default {
  name: 'Home',
  components: {
    AppBar
  },
  data () {
    return {
    }
  },
  methods: {
    gotoExt () {
      window.open(
        this.url,
        '_blank'
      );
    },
    gotoInt () {
      window.location.href = this.dirr
    },
    gotoPage (link) {
      var target = link === this.url ? '_blank' : '_self'
      window.open(
        link,
        target
      );
    },
    onScroll () {
      this.drawer ? this.drawer = false : this.drawer = true
    },
  },
}
</script>
<style scoped>
    @font-face {
      font-family: Letra;
      src: url('../assets/fonts/OpenSans-VariableFont_wdth,wght.ttf');
    }
    @font-face {
      font-family: Laube;
      src: url('../assets/fonts/Blue Vinyl Fonts - BlueVinyl-Bold.otf');
    }
    .laubeFont {
      font-family: Letra !important;
      font-weight: bolder !important;
    }
    .imgweb {
      background-image: url('../assets/img/fundo1web.png');
      background-color: #d4c1ba;
      background-size: cover;
      background-position-y: -100px;
      height: 100vh;
      width: 100%;
      background-repeat: no-repeat;
    }
    .imgmobi {
      background-image: url('../assets/img/fundocel1.png');
      background-color: #d4c1ba;
      background-position: top;
      background-size: cover;
      height: 100vh;
      background-repeat: no-repeat;
    }
</style>
