<template>
  <div class="mt-15">
    <v-app-bar
      color="#70445E"
      dark
      fixed
    >
      <v-app-bar-nav-icon @click.stop="drawr = !drawr"></v-app-bar-nav-icon>
      <v-toolbar-title style="margin: 0 auto;">
        <v-img
          color="white"
          :src="require('../assets/img/logo_branca.png')"
          width="100"
          style="cursor: pointer;"
          @click="$router.push('/');"
        ></v-img>
      </v-toolbar-title>
    </v-app-bar>
    <div v-if="drawr">
      <v-card
        flat
        tile
        width="100%"
        class="text-center"
        style="z-index:1000;position: fixed;background: rgba(253, 237, 230, 1);"
      >
        <v-card-text>
          <v-btn
            v-for="(item, i) in items"
            :key="i"
            class="ma-2"
            depressed
            text
            elevation="0"
          >
            <div style="color: #70445E;">
              <span
                v-if="item.title !== 'livros'"
                class="caption"
                @click="$router.push(`${item.path}`);"
              >{{ item.title }}</span>
              <v-menu
                offset-y
                bottom
                open-on-hover
              >
                <template
                  v-if="item.title === 'livros'"
                  v-slot:activator="{ on, attrs }"
                >
                  <span
                    v-on="on"
                    v-bind="attrs"
                    class="caption"
                  >{{ item.title }}</span>
                </template>
                <v-list
                  v-if="item.title === 'livros'"
                  color="#70445E"
                >
                  <v-list-item
                    v-for="(it, index) in items[1].sub"
                    :key="index"
                  >
                    <v-list-item-title
                      @click="$router.push(`${item.path[index]}`);"
                      class="white--text caption"
                      style="cursor: pointer;"
                    >{{ it }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
          </v-btn>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AppBar',
  props: {
    drawer: Boolean
  },
  data () {
    return {
      drawr: false,
      items: [
        { title: 'a autora', path: 'autora' },
        { title: 'livros', sub: ['Preliminares - nudez no verso','Hoje não pode brincar lá fora'], path: ['livropreliminares','livroHojeNaoPodeBrincarLaFora'] },
        { title: 'antologias', path: 'antologias' },
        { title: 'coluna En Dehors', path: 'colunaendehors' },
        { title: 'trabalho social', path: 'trabalhosocial' },
        { title: 'blog', path: 'blog' },
      ],
    }
  },
  watch: {
    drawer () {
      this.drawr = false
    }
  },
  methods: {
    gotoExt () {
      window.open(
        this.url,
        '_blank'
      );
    },
    gotoInt (url) {
      window.location.href = url
    },
    gotoPage (link) {
      var target = link === this.url ? '_blank' : '_self'
      window.open(
        link,
        target
      );
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  @font-face {
    font-family: Laube;
    src: url('../assets/fonts/Blue Vinyl Fonts - BlueVinyl-Bold.otf');
  }
</style>
