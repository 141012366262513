import Vue from 'vue'
import VueRouter from 'vue-router'
import Index from '../views/Index.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Index',
    component: Index
  },
  {
    path: '/autora',
    name: 'Aautora',
    component: () => import('../views/Aautora.vue')
  },
  {
    path: '/livropreliminares',
    name: 'Preliminares',
    component: () => import('../views/LivroPreliminares.vue')
  },
  {
    path: '/livroHojeNaoPodeBrincarLaFora',
    name: 'HojeNaoPodeBrincarLaFora',
    component: () => import('../views/LivroHojeNaoPodeBrincarLaFora.vue')
  },
  {
    path: '/antologias',
    name: 'Antologias',
    component: () => import('../views/PaginaAntologias.vue')
  },
  {
    path: '/colunaendehors',
    name: 'ColunaEnDehors',
    component: () => import('../views/PaginaColunaEnDehors.vue')
  },
  {
    path: '/trabalhosocial',
    name: 'TrabalhoSocial',
    component: () => import('../views/PaginaTrabalhoSocial.vue')
  },
  {
    path: '/edicaomai-jun',
    name: 'EdicaoMaiJun',
    component: () => import('../views/PaginaColuna_MaiJun.vue')
  },
  {
    path: '/edicaojul-ago',
    name: 'EdicaoJulAgo',
    component: () => import('../views/PaginaColuna_JulAgo.vue')
  },
  {
    path: '/edicaoset-out',
    name: 'EdicaoSetOut',
    component: () => import('../views/PaginaColuna_SetOut.vue')
  },
  // {
  //   path: '/antologias',
  //   name: 'Antologias',
  //   component: () => import('../views/Antologias.vue')
  // },
  // {
  //   path: '/preliminares',
  //   name: 'Preliminares',
  //   component: () => import('../views/Preliminares.vue')
  // },
  // {
  //   path: '/texto-preliminares',
  //   name: 'TextoPreliminares',
  //   component: () => import('../views/TextoPreliminares.vue')
  // },
  {
    path: '/pix-preliminares',
    name: 'PixPreliminares',
    component: () => import('../views/PixPreliminares.vue')
  },
  // {
  //   path: '/hojenaopodebrincarlafora',
  //   name: 'HojeNaoPodeBrincarLaFora',
  //   component: () => import('../views/HojeNaoPodeBrincarLaFora.vue')
  // },
  {
    path: '/hojenaopodebrincarlaforapix',
    name: 'HojeNaoPodeBrincarLaForaPix',
    component: () => import('../views/HojeNaoPodeBrincarLaForaPix.vue')
  },
  {
    path: '/brincadeiradecrianca',
    name: 'BrincadeiradeCrianca',
    component: () => import('../views/BrincadeiradeCrianca.vue')
  },
  {
    path: '/acesso-blog',
    name: 'AcessoBlog',
    component: () => import('../views/AcessoBlog.vue')
  },
  {
    path: '/restrito-blog',
    name: 'RestritoBlog',
    component: () => import('../views/RestritoBlog.vue')
  },
  {
    path: '/blog',
    name: 'ListaBlog',
    component: () => import('../views/ListaBlog.vue')
  },
  {
    path: '/blog/:id',
    name: 'BlogPost',
    props: true,
    component: () => import('../views/BlogPost.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { top: 0 }
  },
})

export default router
